import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBrandAction } from '../../../../store/slices/retail/home/ProductFiltersSlice'
import { AllProductsActions } from '../../../../store/slices/retail/home/AllProductsSlice'
import { useLocation } from 'react-router-dom'
import { GlobalContext } from '../../../../Context'
import Checkbox from '@mui/material/Checkbox';

const BrandsFilter = () => {
    const dispatch = useDispatch()
    const brands = useSelector(state => state.productFilter.brands) || [];
    const { rloading } = useSelector((state) => state.allProducts);
    const [selected, setSelected] = useState([]);
    const pathname = useLocation().pathname;
    const { searchParams, setSearchParams, updateQueryParam, deleteQueryParam } = useContext(GlobalContext);
    useEffect(() => {
        try {
            const Brand_Id = searchParams.get("Brand_Id") ? searchParams.get("Brand_Id").split(",") : []
            if (Brand_Id.length > 0) {
                let notFound = true;
                const selectedBrands = [];
                for (let i = 0; i < brands.length; i++) {
                    for (let j = 0; j < Brand_Id.length; j++) {
                        if (brands[i].id == Brand_Id[j]) {
                            selectedBrands.push(brands[i])
                            notFound = false;
                            break;
                        }
                    }
                }
                setSelected(selectedBrands);
                if (notFound) {
                    setSelected([]);
                }
            } else {
                setSelected([]);
            }
        }
        catch {
            setSelected([]);
        }
    }, [searchParams, brands]);
    useEffect(() => {
        dispatch(getBrandAction(
            {
                formData: { Is_Retail: pathname.includes("retail") ? 1 : 0, Is_Grocery: pathname.includes("retail") ? 0 : 1 }, pagination: { PageNo: 1, PageSize: 1000, SortBy: "BRAND_NAME", SortOrder: "ASC" }
            }
        ))
    }, [])

    return (
        <Box sx={{ width: "100%", margin: "10px 0px" }}>
            <Autocomplete
                disablePortal
                key={"brandFilter"}
                options={brands || []}
                multiple
                limitTags={1}
                sx={{
                    "& fieldset": {
                        borderRadius: "12px",

                    },
                    '.MuiAutocomplete-inputRoot': {
                        flexWrap: 'nowrap !important', overflow: "hidden"
                    }
                }}
                value={selected}
                defaultValue={selected}
                isOptionEqualToValue={(option, value) => {
                    return option.id === value.id
                }}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField  {...params} label={"Search Brands"} />}
                renderOption={(props, option) => (
                    <FormControlLabel {...props} sx={{ width: "98%", }} control={<Checkbox checked={props["aria-selected"]
                    } />} label={option.title} />
                )}
                onChange={(event, newValue) => {
                    if (newValue.length > 0)
                        updateQueryParam([{ Brand_Id: newValue.map((item) => item.id).toString() }])
                    else
                        deleteQueryParam('Brand_Id')
                }}
            />
        </Box>


    )

}
export default BrandsFilter
